import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getGiftCardsBrands } from '../../../utils/service';
import {
  ANCHOR_GIFT_CARDS,
  BRAND_ID_ROUTE_PARAM,
  ROUTE_GIFT_CARDS,
  ROUTE_GIFT_CARDS_BRAND_ID,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import Slider, { SLIDER_TYPES } from '../../../global/Slider';
import { isMobile, SIZE_MD } from '../../../../../hooks/useBreakPoint';
import GiftCardItem from '../GiftCardItem';

import './GiftCardsPreview.scss';

const PREVIEW_PAGE_SIZE = 12;

const SLIDES_PER_PAGE_DEFAULT = 8;
const SLIDES_PER_PAGE_MOBILE_MD = 6;
const SLIDES_PER_PAGE_MOBILE = 4;

const {
  dashboard: {
    earnTrashiecash: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const GiftCardsPreview = () => {
  const history = useHistory();
  const mobile = isMobile();
  const mobileMD = isMobile([SIZE_MD]);

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadGiftCards = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: giftCardsError,
    } = await getGiftCardsBrands({
      pageSize: PREVIEW_PAGE_SIZE,
      page: 1,
    });

    if (giftCardsError) {
      setListData([]);
      setError(giftCardsError);
      setError(giftCardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setError('');
    setListData(data.brands);
    setIsLoading(false);
  }, []);

  const handleOnClick = useCallback(({ uid }) => {
    const relativePath = ROUTE_GIFT_CARDS_BRAND_ID.replace(BRAND_ID_ROUTE_PARAM, uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadGiftCards();
  }, []);

  return (
    <DashboardSection
      anchor={ANCHOR_GIFT_CARDS}
      title="Gift Cards"
      subTitle="Spend your TrashieCash™ on exclusive rewards!"
      onClick={listData.length ? () => history.push(ROUTE_GIFT_CARDS) : undefined}
      isLoading={isLoading}
      error={error}
      noData={listData.length ? undefined : 'No gift cards available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <div className="GiftCardsPreview">
        <Slider
          type={SLIDER_TYPES.OVERFLOW}
          autoplay={false}
          rewind={false}
          paginationDots={false}
          gap={16}
          slidesPerPage={mobileMD
            ? SLIDES_PER_PAGE_MOBILE_MD
            : (mobile && SLIDES_PER_PAGE_MOBILE) || SLIDES_PER_PAGE_DEFAULT}
          slides={listData.map(giftCardItem => ({
            key: `giftCard-preview-${giftCardItem.uid}`,
            slide: (
              <GiftCardItem
                key={`giftCard-slide-${giftCardItem.uid}`}
                giftCardItem={giftCardItem}
                onClick={handleOnClick}
              />
            ),
          }))}
        />
      </div>
    </DashboardSection>
  );
};

export default GiftCardsPreview;
