/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect, useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import LogRocket from 'logrocket';

import {
  EMAIL_SEARCH_PARAM,
  PROMO_ID_PARAM,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  TYPE_SEARCH_PARAM,
} from '../../../config/routes';

import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import { getSearchParam, isRedeemPath } from '../../../utils/routes';
import { getUserName, getUUID } from '../../../utils/utils';

const PrivateRoute = ({
  component: Component,
  componentProps = {},
  ...rest
}) => {
  const { isSignedIn, isLoading, userData } = useFirebaseAuth();
  const { location } = useHistory();
  const posthog = usePostHog();

  if (isLoading) {
    return null;
  }

  // Handle deep link
  if (!isSignedIn) {
    // Store the route the user tried to visit
    // Only handle if the current location is not the login page or register flow
    if (
      location.pathname !== ROUTE_LOGIN &&
      location.pathname !== ROUTE_LOGOUT &&
      !isRedeemPath(location.pathname)
    ) {
      const search = new URLSearchParams(location.search);
      // Set login search params
      const loginSearch = new URLSearchParams({});
      const typeParam = getSearchParam(search, TYPE_SEARCH_PARAM);
      const emailParam = getSearchParam(search, EMAIL_SEARCH_PARAM);
      const promoParam = getSearchParam(search, PROMO_ID_PARAM);
      if (typeParam) {
        loginSearch.set(TYPE_SEARCH_PARAM, typeParam);
      }
      if (emailParam) {
        loginSearch.set(EMAIL_SEARCH_PARAM, emailParam);
      }
      if (promoParam) {
        loginSearch.set(PROMO_ID_PARAM, promoParam);
      }
      // Remove login search params for redirect
      search.delete(TYPE_SEARCH_PARAM);
      search.delete(EMAIL_SEARCH_PARAM);
      search.delete(PROMO_ID_PARAM);

      return (
        <Redirect
          to={{
            pathname: ROUTE_LOGIN,
            search: loginSearch.toString(),
            state: {
              from: {
                ...location,
                search: search.toString(),
              },
            },
          }}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn && !isLoading) {
          // Posthog - Identify user if not already identified
          const uuid = getUUID(userData);
          if (posthog.get_distinct_id() !== uuid) {
            posthog.identify(
              uuid,
              { email: userData?.email },
            );
          }
          // LogRocket - Identify user
          LogRocket.identify(String(uuid), {
            email: userData?.email,
            name: getUserName(userData),
          });

          return <Component {...props} {...componentProps} />;
        }

        return <Redirect to={ROUTE_LOGIN} />;
      }}
    />
  );
};

export default PrivateRoute;
