import { useCallback, useMemo } from 'react';
import { usePostHog } from 'posthog-js/react';
import classNames from 'classnames';

import { Flag } from '../../../global/Rewards';
import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { REWARD_AMOUNT_TYPES } from '../../../config/rewards';
import {
  isDonationType,
  isLimitErrorCode,
  isLockedErrorCode,
  isNotFoundErrorCode,
  isUserLimitErrorCode,
} from '../../../utils/rewards';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import TCCoinSvg from '../../../global/SvgComponents/TCCoin_svg';
import ChevronUpSvg from '../../../global/SvgComponents/ChevronUp_svg';
import IconGemCircle from '../../../images/icon-gem-circle.svg';
import Lock from '../../../images/icon-lock-circle.svg';
import { TAKE_BACK_BAG_COLLECTION_URL } from '../../../config/externalURL';

import './RewardItem.scss';

const {
  rewards: {
    list: {
      rewardItem: trackerRewardItem,
      claimButton: trackerClaimButton,
    },
  },
} = TRACKER_IDS;

const getCtaLabel = ({
  isDeal,
  exchangeValue,
  type,
  disabled,
  status,
  hasSufficientFunds }) => {
  if (isDeal) {
    if (disabled) {
      return isLockedErrorCode(status) ? 'Recycle To Unlock' : 'Deal details';
    }
    return 'Claim Deal';
  }

  const isDonation = isDonationType(type);

  if (disabled) {
    if (!hasSufficientFunds) {
      return (
        <span className="RewardsViewItem__cta--label--locked">
          {`Earn ${exchangeValue} `}
          <TCCoinSvg width="12" height="12" />
          {' '}
          to unlock
        </span>
      );
    }
    if (isLockedErrorCode(status)) {
      return 'Recycle To Unlock';
    }

    return `${isDonation ? 'Donation' : 'Reward'} details`;
  }

  return `${isDonation ? 'Donate' : 'Get it for'} ${exchangeValue}`;
};

const RewardItem = ({
  rewardItem,
  onClick,
  rewardProperties,
  withCta = true,
  trackerProps = {},
  index,
}) => {
  const { userData, isSignedIn, user } = useFirebaseAuth();
  const posthog = usePostHog();

  const {
    balance: {
      currentBalance: currentBalanceData = 0,
    },
  } = userData;

  const {
    uid: rewardId,
    name: rewardName,
    description: rewardDescription,
    amountType,
    rewardAmount,
    type,
    exchangeValue,
    status,
    isNew,
    campaignEndDate,
    rewardCodes,
    allowTcCredits,
    store: {
      logoUrl,
      imgUrl,
      RewardLogoProperties,
      tcCreditAmount,
    },
  } = useMemo(() => rewardItem, [rewardItem]);

  const isDeal = useMemo(() => Number(exchangeValue) === 0, [exchangeValue]);
  const hasSufficientFunds = useMemo(
    () => parseFloat(currentBalanceData) >= parseFloat(exchangeValue),
    [currentBalanceData, exchangeValue],
  );
  const lockedItem = useMemo(
    () => isLockedErrorCode(status) || !hasSufficientFunds,
    [status, hasSufficientFunds],
  );

  const disabled = useMemo(() => (
    isLimitErrorCode(status) || isUserLimitErrorCode(status) ||
    isNotFoundErrorCode(status) || lockedItem
  ), [status, lockedItem]);

  const amountText = useMemo(() => (
    REWARD_AMOUNT_TYPES[amountType]?.getText({ rewardAmount, type }) ?? ''
  ), [amountType, rewardAmount]);

  const handleOnClick = useCallback((fromCta = false) => {
    const claim = fromCta && isDeal && !disabled;

    posthog.capture(
      getEventName(claim ? trackerClaimButton : trackerRewardItem),
      {
        [POSTHOG_PROPERTIES.REWARD_ID]: rewardId,
        [POSTHOG_PROPERTIES.REWARD_NAME]: rewardName,
      },
    );

    if (lockedItem && fromCta) {
      window.location.href = TAKE_BACK_BAG_COLLECTION_URL;
      return;
    }

    onClick?.(
      rewardItem,
      fromCta && !disabled,
    );
  }, [rewardId, rewardName, onClick, isSignedIn, user, disabled, rewardItem, lockedItem]);

  const statusIcon = useMemo(() => {
    if (lockedItem) {
      return (
        <div className="RewardsViewItem__lockedIcon">
          <img
            className="RewardsViewItem__lockedIcon--icon"
            src={disabled ? Lock : IconGemCircle}
            alt="reward item"
          />
        </div>
      );
    }

    if (!isDeal) {
      return (
        <img
          className="RewardsViewItem__gem"
          src={IconGemCircle}
          alt="reward item"
        />
      );
    }
    return null;
  }, [lockedItem, disabled, isDeal]);

  const rewardItemTrackerProps = useMemo(() => {
    const tracker = isDeal && !disabled ? trackerClaimButton : trackerRewardItem;

    return {
      [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(tracker),
      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(tracker),
      [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: rewardId,
      [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: rewardName,
      [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_IS_DEAL]: isDeal,
      ...(!Number.isNaN(index) && {
        [POSTHOG_CAPTURE_ATTRIBUTES.LIST_POSITION]: Number(index) + 1,
      }),
      ...trackerProps,
    };
  }, [isDeal, disabled, rewardId, rewardName, trackerProps, index]);

  return (
    <div className={classNames('RewardsViewItem', { withCta, disabled })}>
      {statusIcon}
      <Flag
        status={status}
        isNew={isNew}
        isFreeReward={isDeal}
        hasRewardCodes={rewardCodes.length > 0}
        exchangeValue={exchangeValue}
        expireAt={campaignEndDate}
        allowTcCredits={allowTcCredits}
        tcCreditAmount={tcCreditAmount}
        properties={rewardProperties}
      />
      <button
        className={classNames('RewardsViewItem__caret', { disabled })}
        id={`RewardsViewItem__caret-${rewardItem.uid}`}
        onClick={() => handleOnClick()}
        type="button"
        aria-label="reward item caret"
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleOnClick();
          }
        }}
        {...rewardItemTrackerProps}
      >
        <div
          className="RewardsViewItem__caret--cover"
          alt="store image"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${imgUrl})`,
          }}
        >
          <img
            className="RewardsViewItem__caret--cover-logo"
            src={logoUrl}
            style={{
              ...RewardLogoProperties,
              ...(RewardLogoProperties?.height ? { maxHeight: RewardLogoProperties.height, height: 'unset' } : {}),
            }}
            alt="store logo"
          />
        </div>
        <div className="RewardsViewItem__caret--content">
          <div className="RewardsViewItem__caret--content-text">
            <p className="RewardsViewItem__caret--content-text-title" title={amountText}>{amountText}</p>
            <p className="RewardsViewItem__caret--content-text-subtitle" title={rewardDescription}>{rewardDescription}</p>
          </div>
          <div className="RewardsViewItem__caret--content-arrow">
            <ChevronUpSvg
              color="var(--trashie-ui-faded-black-80)"
              width={12}
            />
          </div>
        </div>
      </button>
      {withCta && (
        <button
          type="button"
          className="RewardsViewItem__cta"
          id={`RewardsViewItem__cta-${rewardItem.uid}`}
          onClick={() => handleOnClick(true)}
          aria-label="reward item action"
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              handleOnClick(true);
            }
          }}
          {...rewardItemTrackerProps}
        >
          <span className="RewardsViewItem__cta--label">
            {getCtaLabel({
              isDeal,
              exchangeValue,
              type,
              disabled,
              status,
              hasSufficientFunds })}
          </span>
          {!isDeal && !disabled && (
            <TCCoinSvg width="12" height="12" />
          )}
        </button>
      )}
    </div>
  );
};

export default RewardItem;
