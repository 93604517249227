import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { ROUTE_DEALS, ROUTE_GIFT_CARDS, ROUTE_REWARDS } from '../../../config/routes';
import { SUPPORT_URL } from '../../../config/externalURL';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import {
  GENERIC_CODE_ERROR,
  GIFT_CARDS_FUNDS_CODE_ERROR,
  NOT_FOUND_ERROR,
  REWARDS_FUNDS_CODE_ERROR,
  REWARDS_NO_MORE_CODES,
  REWARDS_NO_MORE_CODES_PER_USER,
} from '../../../utils/errors';
import { TRACKER_EVENT_ERROR_TYPES } from '../../../config/tracker';

import './RewardContent.scss';

const getErrorData = ({
  errorId,
  isDeal,
  isGiftCard,
}) => {
  let label = 'reward';
  let route = ROUTE_REWARDS;

  if (isGiftCard) {
    label = 'gift card';
    route = ROUTE_GIFT_CARDS;
  } else if (isDeal) {
    label = 'deal';
    route = ROUTE_DEALS;
  }

  let title = '';
  let subtitle = '';
  let instruction = `Check out other great ${label}s available in your dashboard`;

  switch (errorId) {
    case REWARDS_FUNDS_CODE_ERROR:
    case GIFT_CARDS_FUNDS_CODE_ERROR:
      title = 'We’re sorry!';
      subtitle = 'You need more TrashieCash™';
      break;
    case REWARDS_NO_MORE_CODES:
      title = 'We’re sorry!';
      subtitle = `We’ve run out of this ${label}`;
      break;
    case REWARDS_NO_MORE_CODES_PER_USER:
      title = `${label} limit reached`;
      subtitle = `You’ve reached the limit for this ${label}`;
      break;
    case NOT_FOUND_ERROR:
      title = 'We’re sorry!';
      subtitle = `We couldn’t find this ${label}`;
      break;
    case GENERIC_CODE_ERROR:
    default:
      title = 'OOPS!';
      subtitle = `We encountered an error while processing your ${label}`;
      instruction = 'Retry the action after a few seconds.';
  }

  return {
    title,
    subtitle,
    instruction,
    backText: `BACK TO ${label.toUpperCase()}S`,
    route,
  };
};

const ModalContentError = ({
  errorId,
  onBack,
  isDeal,
  isGiftCard,
}) => {
  const history = useHistory();
  const posthog = usePostHog();

  const {
    title,
    subtitle,
    instruction,
    backText,
    route,
  } = getErrorData({
    errorId,
    isDeal,
    isGiftCard,
  });

  useEffect(() => {
    const eventErrorId = errorId || GENERIC_CODE_ERROR;
    const eventName = isGiftCard
      ? TRACKER_EVENT_ERROR_TYPES.GIFT_CARDS[eventErrorId]
      : TRACKER_EVENT_ERROR_TYPES.REWARDS[eventErrorId];

    if (!eventName) {
      return;
    }

    posthog.capture(eventName);
  }, []);

  return (
    <div className="body-style error-modal">
      <div className="body-top">
        <div className="bold-title">
          {title}
        </div>
        <div className="error-text">
          <p className="title">
            {subtitle}
          </p>
          <p className="description">
            {instruction}
          </p>
          {!errorId && (
            <p className="description">
              If the issue persists, please contact support.
            </p>
          )}
        </div>
        <div className="error-footer">
          <Button
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => {
              history.push(route);
              onBack?.();
            }}
          >
            {backText}
          </Button>
          {!errorId && (
            <a className="contact-support-btn" href={SUPPORT_URL} target="_blank" rel="noreferrer">
              Contact support
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContentError;
