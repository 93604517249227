import { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { getSustainabilityImpactUser } from '../../utils/service';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';
import { formatQuantity } from '../../utils/numbers';
import Co2EmissionIcon from '../../images/co2-emissions.svg';
import FreshWaterIcon from '../../images/fresh-water.svg';
import LandfillWasteIcon from '../../images/landfill-waste.svg';
import LoadingBar from '../LoadingBar';

import './ImpactMetrics.scss';

const defaultWinsData = {
  base: [],
  impact: [],
  redemptions: 0,
};

const equivalenceMetrics = {
  landfillDiversion: 'avocados',
  co2EmissionDiversion: 'suvMiles',
  waterSaved: 'laundryLoads',
};

const emptyMetrics = {
  landfillDiversion: { title: 'Get Started', label: 'Divert landfill waste when recycling with Trashie' },
  co2EmissionDiversion: { title: 'Act Now', label: 'Water will be saved as well' },
  waterSaved: { title: 'Don’t Wait', label: 'Divert CO2 emissions too!' },
};
const ImpactTitle = 'My Environmental Impact';

const ImpactMetrics = () => {
  const { isLoading: isFirebaseAuthLoading, user } = useFirebaseAuth();
  const setAlert = useAlert();

  const [winsData, setWinsData] = useState(defaultWinsData);
  const [isLoading, setIsLoading] = useState(true);

  const loadWins = useCallback(async () => {
    setIsLoading(true);

    const { data: userWins, error } = await getSustainabilityImpactUser();

    if (error) {
      setAlert({
        type: 'alert',
        message: error,
        error,
      });
      setWinsData(defaultWinsData);
      setIsLoading(false);

      return;
    }

    setWinsData(userWins);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isFirebaseAuthLoading && user) {
      loadWins();
    }
  }, [isFirebaseAuthLoading, user]);

  const formaterNumber = (quantity) => {
    const { value, suffix } = formatQuantity(quantity);
    return `${value}${suffix}`;
  };

  const metricsLabel = (metricId, label, icon, contrast = false, bigNumber = false) => {
    const { unit, quantity, equivalences } =
      winsData.impact.find((item) => item.id === metricId) ?? { unit: '', quantity: 0, equivalences: [] };
    const { label: eqLabel, quantity: eqQuantity } =
      equivalences.find((item) => item.id === equivalenceMetrics[metricId]) ?? {};

    const isEmptyState = quantity === 0 || !equivalences;

    return (
      <div className={classNames('ImpactMetrics__metrics', { 'ImpactMetrics__metrics--contrast': contrast, 'ImpactMetrics__metrics--bigger': bigNumber })}>
        <div className={classNames('ImpactMetrics__metric-number', { 'ImpactMetrics__metric-number--bigger': bigNumber })}>
          {isEmptyState ? emptyMetrics[metricId].title : `${formaterNumber(quantity)} ${unit}`}
        </div>
        <div className={classNames('ImpactMetrics__metric-label', { 'ImpactMetrics__metrics--contrast': contrast })}>
          {isEmptyState ? emptyMetrics[metricId].label : label}
        </div>
        {!isEmptyState && (
          <div className={classNames('ImpactMetrics__metric-equivalence', { 'ImpactMetrics__metrics--contrast': contrast })}>
            {`= ${formaterNumber(eqQuantity)} ${eqLabel}`}
          </div>
        )}
        <div className={classNames('ImpactMetrics__icon-container', { 'ImpactMetrics__icon-container--bigger': bigNumber })}>
          <img className="ImpactMetrics__icon" src={icon} alt="landIcon" />
        </div>
      </div>
    );
  };

  return (
    <div className="ImpactMetrics">
      <div className="ImpactMetrics__container">
        <div className="ImpactMetrics__title">
          {ImpactTitle}
        </div>
        {isLoading ?
          (
            <div className="ImpactMetrics__load-container">
              <LoadingBar />
            </div>
          ) : (
            <div className="ImpactMetrics__content">
              <div className="ImpactMetrics__content-item ImpactMetrics__content-item--land">
                {metricsLabel('landfillDiversion', 'Landfill Waste Diverted', LandfillWasteIcon, false, true)}
              </div>
              <div className="ImpactMetrics__content-item ImpactMetrics__content-item--air">
                {metricsLabel('co2EmissionDiversion', 'Fresh Water Saved', FreshWaterIcon, true)}
              </div>
              <div className="ImpactMetrics__content-item ImpactMetrics__content-item--water">
                {metricsLabel('waterSaved', 'CO2 Emissions Diverted', Co2EmissionIcon)}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ImpactMetrics;
