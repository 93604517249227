import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { REWARD_ID_PARAM, REWARD_ID_ROUTE_PARAM, ROUTE_REWARD_ID } from '../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../config/tracker';
import { TRASHIE_WEBSITE_URL } from '../../config/externalURL';
import Dashboard from '../../global/Dashboard';
import DealsPreview from '../Deals/DealsPreview';
import DealsPreviewBanner from '../Deals/DealsPreview/DealsPreviewBanner';
import OffersPreview from '../Offers/OffersPreview';
import { getCategories, getEarningsCarousel } from '../../utils/service';
import useDealsSegmentation, { TBB_SEGMENTS_TYPES } from '../../../../hooks/useDealsSegmentation';
import { isMobile, SIZE_SM, SIZE_XS } from '../../../../hooks/useBreakPoint';

import './DashboardDeals.scss';
import { REWARD_PREVIEW_TYPES } from '../../config/rewards';

const {
  dashboard: {
    marketing: {
      slide: trackerSlide,
    },
  },
} = TRACKER_IDS;

const DashboardDeals = () => {
  const history = useHistory();
  const isMobileXsSm = isMobile([SIZE_XS, SIZE_SM]);

  const {
    isLoading: isLoadingSegmentation,
    isNonTbbUser,
    dashboardCategories,
  } = useDealsSegmentation();

  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [categories, setCategories] = useState([]);
  const [carouselSlides, setCarouselSlides] = useState([]);
  const [isLoadingCarousel, setIsLoadingCarousel] = useState(true);

  const isLoading = isLoadingSegmentation || isLoadingCategories || isLoadingCarousel;

  const loadCategories = useCallback(async () => {
    setIsLoadingCategories(true);

    const { data: categoriesData } = await getCategories({});

    setCategories(categoriesData.categories);
    setIsLoadingCategories(false);
  }, []);

  const loadCarousel = useCallback(async () => {
    setIsLoadingCarousel(true);

    const { data } = await getEarningsCarousel();

    setCarouselSlides(data ?? []);
    setIsLoadingCarousel(false);
  }, []);

  const getDealsContent = useCallback(() => {
    if (isLoading) {
      return null;
    }

    if (isNonTbbUser) {
      const trackerProps = {
        [`${POSTHOG_CAPTURE_ATTRIBUTES.SEGMENT}-${TBB_SEGMENTS_TYPES.NON_TBB_USERS}`]: isNonTbbUser,
      };

      return (
        <>
          <DealsPreview
            title="Starter Deals"
            subTitle="Save instantly, earn bonus TrashieCash™"
            type={REWARD_PREVIEW_TYPES.STARTER}
            showOnEmptyResults
            trackerProps={trackerProps}
          />
          <DealsPreviewBanner
            trackerProps={trackerProps}
          />
        </>
      );
    }

    return (
      <>
        <DealsPreview
          title="Featured Deals"
          type={REWARD_PREVIEW_TYPES.FEATURED}
        />
        <DealsPreview
          title="Hot Deals"
          type={REWARD_PREVIEW_TYPES.HOT}
          showOnEmptyResults
        />
        {carouselSlides[0] && (
          <a
            href={carouselSlides[0].redirectUrl || TRASHIE_WEBSITE_URL}
            alt="slide"
            className="DashboardDeals__slide"
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerSlide),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: carouselSlides[0].posthogId,
            }}
          >
            <img
              src={(isMobileXsSm
                ? carouselSlides[0].mobileImageUrl
                : carouselSlides[0].desktopImageUrl
              )}
              alt="slide"
            />
          </a>
        )}
        {dashboardCategories.map(categoryId => {
          const category = categories.find(({ uid }) => uid === categoryId);

          if (!category) {
            return null;
          }

          return (
            <DealsPreview
              title={category.name}
              type={REWARD_PREVIEW_TYPES.CATEGORY}
              category={category}
            />
          );
        })}
      </>
    );
  }, [
    isLoading,
    isNonTbbUser,
    dashboardCategories,
    categories,
    isMobileXsSm]);

  useEffect(() => {
    // Start support for old rewards url from webflow
    const searchParams = new URLSearchParams(history.location.search);
    const searchParamRewardId = searchParams.get(REWARD_ID_PARAM);

    if (searchParamRewardId) {
      history.replace(ROUTE_REWARD_ID.replace(REWARD_ID_ROUTE_PARAM, searchParamRewardId));
    }
    // End support for old rewards url from webflow

    loadCategories();
    loadCarousel();
  }, []);

  return (
    <Dashboard>
      {getDealsContent()}
      <OffersPreview />
    </Dashboard>
  );
};

export default DashboardDeals;
