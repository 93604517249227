/* eslint-disable camelcase */
import { useEffect, useCallback, useState, useRef } from 'react';
import './TakeBackBags.scss';
import crud from '../../../../api/crud';
import ItemList from '../../global/ItemList';
import ListHeaders from '../../global/ListHeaders';
import LoadingBar from '../../global/LoadingBar';
import ListPaginator from '../../global/ListPaginator';
import { TBB_LIST_BY_USER } from '../../config/service';
import { useAlert } from '../../../providers/AlertProvider';
import { ANCHOR_MY_TBB } from '../../config/routes';
import GenericBanner from '../../global/Banner/GenericBanner';
import useDealsSegmentation from '../../../../hooks/useDealsSegmentation';
import {
  HOW_WE_RECYCLE_URL,
  TAKE_BACK_BAG_COLLECTION_URL,
  TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
  TAKE_BACK_BAG_URL,
  TECH_BACK_BOX_SMALL_URL,
  TECH_BACK_BOX_MEDIUM_URL,
} from '../../config/externalURL';
import TBBSmallBox from '../../images/tbb-small-box.webp';
import TBMediumBox from '../../images/tbb-midium-box.webp';
import TBBIphones from '../../images/tech-tbb-iphones.webp';
import TBBSingle from '../../images/tbb-single.png';
import TBB3Pack from '../../images/tbb-3-pack.png';
import TBBIcon from '../../images/tbb-icon.webp';
import StartPurpleIcon from '../../images/star-purple.webp';

import DashboardSection from '../../global/DashboardSection';
import TakeBackBagsListItem from '../../global/TakeBackBagsListItem';
import Slider, { SLIDER_TYPES } from '../../global/Slider';

const PAGE_SIZE = 10;
const SLIDES_PER_PAGE_DEFAULT = 4;
const TBB_ITEMS = [
  {
    id: 1,
    label: 'The single',
    url: TAKE_BACK_BAG_URL,
    imgUrl: TBBSingle,
    bgColor: '#9AD2FF',
  },
  {
    id: 2,
    label: 'The 3-pack',
    url: TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
    imgUrl: TBB3Pack,
    bgColor: '#D8C6FF',
  },
  {
    id: 3,
    label: 'Small Tech Take Back Box',
    url: TECH_BACK_BOX_SMALL_URL,
    imgUrl: TBBSmallBox,
    bgColor: '#FEF288',
  },
  {
    id: 4,
    label: 'Medium Tech Take Back Box',
    url: TECH_BACK_BOX_MEDIUM_URL,
    imgUrl: TBMediumBox,
    bgColor: '#ADD6FF',
  },
];

const itemListHeader = {
  title: 'My shipping labels',
  sortedText: 'Most recently registered',
};

const recycleContent = {
  title: 'How we recycle',
  description: 'Learn more about our innovative sorting and grading system that enables getting unwanted clothing and e-waste to the right place.',
  ctaLabel: 'Learn more',
  ctaURL: HOW_WE_RECYCLE_URL,
  srcImage: TBBIphones,
};

const nonTbbUserContent = {
  title: 'Start your recycling era',
  description: '1. Shop Take Back.\n2. Fill it and ship it.\n3. Unlock a world of deals.',
  ctaLabel: 'Shop Take Back',
  ctaURL: TAKE_BACK_BAG_COLLECTION_URL,
  srcImage: TBBIcon,
  nameId: 'nonTbbUserContent',
  backgroundImg: StartPurpleIcon,
};

function parseTbbData(redemption) {
  const { labeldata, recyclingProduct, ...rest } = redemption;
  let parsedRedemption = {
    recyclingProductTypeId: recyclingProduct?.recyclingProductTypeId,
    ...rest,
  };

  if (labeldata) {
    parsedRedemption = {
      ...parsedRedemption,
      labeldata: JSON.parse(labeldata),
    };
  }

  return parsedRedemption;
}

const TakeBackBags = () => {
  const timeoutRef = useRef(null);

  const [tbbData, setTbbData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const setAlert = useAlert();

  const {
    isLoading: isLoadingSegmentation,
    isNonTbbUser,
  } = useDealsSegmentation();

  const isLoading = isLoadingSegmentation || loading;
  const userTbb = useCallback(
    async () => {
      setLoading(true);
      try {
        const { count, result } = await crud.get({ path: `${TBB_LIST_BY_USER}?page=${currentPage}&pageSize=${PAGE_SIZE}` });
        const data = result.map((redemption) => parseTbbData(redemption));
        setTbbData(data);
        setTotalRecords(count);
      } catch (error) {
        setAlert({
          type: 'alert',
          message: error?.error?.message,
          error: error?.error?.message,
        });
      }
      setLoading(false);
      const mainElement = document.getElementById(ANCHOR_MY_TBB);

      if (mainElement) {
        timeoutRef.current = setTimeout(() => {
          mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
      }
    },
    [currentPage],
  );

  useEffect(() => {
    userTbb();
  }, [currentPage]);

  return (
    <div id={ANCHOR_MY_TBB} className="TakeBackBags">
      <div className="TakeBackBags__container">
        {isNonTbbUser && (
          <GenericBanner
            content={nonTbbUserContent}
          />
        )}
        <DashboardSection
          title="Shop the TBB"
          onClick={
            tbbData.length ?
              () => { window.location.href = TAKE_BACK_BAG_COLLECTION_URL; }
              : undefined
          }
          contained
        >
          <div className="TakeBackBags__slider">
            <Slider
              sliderId="tbbSlider"
              type={SLIDER_TYPES.OVERFLOW}
              autoplay={false}
              rewind={false}
              paginationDots={false}
              gap={16}
              slidesPerPage={SLIDES_PER_PAGE_DEFAULT}
              slides={TBB_ITEMS.map((tbbItem) => ({
                key: `tbb-${tbbItem.id}`,
                slide: (
                  <TakeBackBagsListItem
                    key={`tbb-${tbbItem.id}`}
                    tbbItem={tbbItem}
                  />),
              }))}
            />
          </div>
        </DashboardSection>
        {isLoading && <LoadingBar />}
        {!isLoading && tbbData.length > 0 && (
          <>
            <ListHeaders
              titleSectionClassName="TitleSectionAccount_titleTransForm"
              title={itemListHeader.title}
              sortedText={itemListHeader.sortedText}
            />
            <div className="TakeBackBags__items">
              <ItemList dataList={tbbData} setTbbData={setTbbData} />
              {totalRecords > PAGE_SIZE && (
                <ListPaginator
                  pageSize={PAGE_SIZE}
                  totalItems={totalRecords}
                  currentPage={currentPage}
                  onPageSelection={(selectedPage) => {
                    setCurrentPage(selectedPage);
                  }}
                />
              )}
            </div>
          </>
        )}
        <GenericBanner
          content={recycleContent}
        />
      </div>
    </div>

  );
};

export default TakeBackBags;
