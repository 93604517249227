import GeneralModal from '../GeneralModal';
import { SelectShippingProviderView } from '../../views/Register/Steps';

import './ShippingMethodsModal.scss';

const ShippingMethodsModal = ({
  showModal,
  onClose,
  storeId,
  recyclingProductTypeId,
  setShippingMethodSelected,
  selectedShippingMethodIndex,
  setSelectedShippingMethodIndex,
  isExpiredLabel,
  onSubmit,
  address,
  descriptionText,
  canClose = true,
}) => (
  <GeneralModal
    showModal={showModal}
    showModalHeader={false}
    showModalFooter={false}
    onClose={onClose}
    showClose={canClose}
    canClose={canClose}
  >
    <div className="ShippingMethodsModal">
      <SelectShippingProviderView
        storeConfig={{ uid: storeId }}
        descriptionText={descriptionText}
        recyclingProductType={recyclingProductTypeId}
        setShippingMethod={setShippingMethodSelected}
        selectedShippingMethodIndex={selectedShippingMethodIndex}
        setSelectedShippingMethodIndex={setSelectedShippingMethodIndex}
        isExpiredLabel={isExpiredLabel}
        onSubmitShippingMethod={onSubmit}
        address={address}
      />
    </div>
  </GeneralModal>
);

export default ShippingMethodsModal;
