import classNames from 'classnames';
import RewardItem from '../RewardItem';

import './RewardsList.scss';

const RewardsList = ({
  rewardsList,
  onClick,
  rewardProperties,
  className,
  withCta = true,
  trackerProps = {},
}) => (
  <div className={classNames('RewardsViewList', className)}>
    {rewardsList.map((rewardItem, index) => (
      <RewardItem
        key={`reward-${rewardItem.uid}`}
        index={index}
        rewardItem={rewardItem}
        onClick={onClick}
        rewardProperties={rewardProperties}
        withCta={withCta}
        trackerProps={trackerProps}
      />
    ))}
  </div>
);

export default RewardsList;
