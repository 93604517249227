import Button, { BUTTON_TYPE } from '../Button/Button';
import './ItemListContainer.scss';

const ItemListContainer = ({ listTitle, items, exitIcon, history }) => (
  <div className="ItemListContainer">
    <div className="ItemListContainer__title">{listTitle}</div>
    {items.map(({ label, route, icon, title, external = false }) => (
      <Button
        key={title}
        type={BUTTON_TYPE.SENARY}
        className="ItemListContainer__item"
        onClick={() => {
          if (external) {
            window.open(route);
            return;
          }

          history.push(route);
        }}
      >
        <div className="ItemListContainer__item-content">
          <img className="ItemListContainer__item-icon" src={icon} alt="edit" />
          <div className="ItemListContainer__item-text">
            <span className="ItemListContainer__item-title">{title}</span>
            <span className="ItemListContainer__item-lable">{label}</span>
          </div>
          <img className="ItemListContainer__item-icon" src={exitIcon} alt="edit" />
        </div>
      </Button>
    ))}
  </div>
);

export default ItemListContainer;
