const REACT_APP_API_PATH_V1 = '/v1';
const EARNINGS_PATH = `${REACT_APP_API_PATH_V1}/earnings`;
const EARNINGS_SUMMARY_PATH = `${EARNINGS_PATH}/summary`;
const EARNINGS_TRANSACTIONS_PATH = `${EARNINGS_PATH}/transactions`;
const EVENT_STREAM_PATH = `${REACT_APP_API_PATH_V1}/event-stream`;
const SUSTAINABILITY_IMPACT_USER_PATH = `${REACT_APP_API_PATH_V1}/sustainability-impact/user`;
const CONFIGURATION_PATH = `${REACT_APP_API_PATH_V1}/configurations`;
const OFFERS_PATH = `${REACT_APP_API_PATH_V1}/offers`;
const PUBLIC_OFFERS_PATH = `${OFFERS_PATH}/public`;
const REWARDS_PATH = `${REACT_APP_API_PATH_V1}/rewards`;
const REWARDS_OFFER_PATH = `${REWARDS_PATH}/offer`;
const REWARDS_USER_PATH = `${REACT_APP_API_PATH_V1}/rewards/user`;
const GIFT_CARDS_PATH = `${REACT_APP_API_PATH_V1}/gift-cards`;
const GIFT_CARDS_BRANDS_PATH = `${GIFT_CARDS_PATH}/brands`;
const BRAND_ID_PARAM = ':brandId';
const GIFT_CARDS_BRAND_PATH = `${GIFT_CARDS_PATH}/brands/${BRAND_ID_PARAM}`;
const GIFT_CARDS_CATEGORIES_PATH = `${GIFT_CARDS_PATH}/categories`;
const GIFT_CARDS_BRAND_SEARCH_PATH = `${GIFT_CARDS_PATH}/brands/search`;
const GIFT_CARD_ID_PARAM = ':giftCardId';
const GIFT_CARD_BY_UID_PATH = `${GIFT_CARDS_PATH}/${GIFT_CARD_ID_PARAM}`;
const GIFT_CARDS_ISSUE_PATH = `${GIFT_CARDS_PATH}/issue`;
const GIFT_CARDS_USER_PATH = `${GIFT_CARDS_PATH}/user`;
const GIFT_CARDS_USED_PATH = `${GIFT_CARDS_PATH}/${GIFT_CARD_ID_PARAM}/used`;
const USERS_PATH = `${REACT_APP_API_PATH_V1}/users`;
const USERS_ADDRESSES_PATH = `${USERS_PATH}/addresses`;
const USERS_STATUS_PATH = `${USERS_PATH}/status`;
const REWARD_ID_PARAM = ':rewardId';
const REWARD_PATH = `${REWARDS_PATH}/${REWARD_ID_PARAM}`;
const REWARDS_CLAIM_PATH = `${REWARDS_PATH}/${REWARD_ID_PARAM}/claim`;
const REWARDS_USED_PATH = `${REWARDS_PATH}/${REWARD_ID_PARAM}/used`;
const REWARDS_CODE_BY_UID_AND_BY_USER = `${REWARDS_PATH}/user/reward-code/${REWARD_ID_PARAM}`;
const TBB_LIST_BY_USER = `${REACT_APP_API_PATH_V1}/take-back-bags`;
const STORES_PATH = '/stores';
const STORE_ID_PARAM = ':storeId';
const STORE_PATH = `${STORES_PATH}/${STORE_ID_PARAM}`;
const REWARDS_STORE_PATH = `${REWARDS_PATH}/store/${STORE_ID_PARAM}`;
const SHOPIFY_STORE_PARAM = ':shop';
const DISCOUNT_CODE_ID_PARAM = ':discountCodeId';
const CLOSET_CASH_DISCOUNT_CODES_PATH = `${REACT_APP_API_PATH_V1}/shop/${SHOPIFY_STORE_PARAM}/discount-codes`;
const CLOSET_CASH_DISCOUNT_CODE_CLAIM_PATH = `${CLOSET_CASH_DISCOUNT_CODES_PATH}/${DISCOUNT_CODE_ID_PARAM}/claim`;
const REDEMPTION_CODE_PARAM = ':redemptionCode';
const SHIPPING_LABELS_PATH = '/shipping-labels';
const REGENERATION_SHIPPING_LABEL_PATH = `${REACT_APP_API_PATH_V1}${SHIPPING_LABELS_PATH}/regenerate/${REDEMPTION_CODE_PARAM}`;
const TOTAL_IMPACT_PATH = `${REACT_APP_API_PATH_V1}/impact-dashboard/store/${STORE_ID_PARAM}/totalImpact`;
const CATEGORIES_PATH = `${REACT_APP_API_PATH_V1}/categories`;
const EARNINGS_CAROUSEL_PATH = `${EARNINGS_PATH}/carousel`;
const SEND_SIGN_IN_EMAIL_LINK_PATH = `${REACT_APP_API_PATH_V1}/auth/send-sign-in-email-link`;
const SHIPPING_PROVIDERS_PATH = `${REACT_APP_API_PATH_V1}/shipping-providers`;
const REDEMPTIONS_PATH = '/redemptions';
const AUTH_USERS_PATH = `${REACT_APP_API_PATH_V1}/auth/users`;
const SIMILAR_AND_TRENDING_REWARDS_PATH = `${REACT_APP_API_PATH_V1}/trending/rewards`;
const FEATURED_REWARDS_PATH = `${REWARDS_PATH}/featured`;

const EVENT_STREAM_EVENT_TYPES = {
  USER_REGISTERED: 'user-registered',
  USER_LOGGED_IN: 'user-logged-in',
  USER_LOGGED_IN_WF_EXTENSION: 'user-logged-in-wf-extension',
  USER_LAST_VISIT: 'user-last-visit',
};

const EVENT_STREAM_APP_TYPES = {
  TRASHIE: 'trashie',
};

const SHOPIFY_STORES = {
  TRASHIE_STORE: 'trashie',
  CLOSET_CASH_STORE: 'closet-cash',
};

export {
  EARNINGS_SUMMARY_PATH,
  EARNINGS_TRANSACTIONS_PATH,
  EVENT_STREAM_PATH,
  SUSTAINABILITY_IMPACT_USER_PATH,
  EVENT_STREAM_EVENT_TYPES,
  EVENT_STREAM_APP_TYPES,
  CONFIGURATION_PATH,
  OFFERS_PATH,
  PUBLIC_OFFERS_PATH,
  REWARDS_PATH,
  REWARDS_USER_PATH,
  USERS_ADDRESSES_PATH,
  USERS_STATUS_PATH,
  REWARD_ID_PARAM,
  REWARD_PATH,
  REWARDS_CLAIM_PATH,
  REWARDS_USED_PATH,
  TBB_LIST_BY_USER,
  STORES_PATH,
  STORE_ID_PARAM,
  STORE_PATH,
  REWARDS_STORE_PATH,
  REWARDS_OFFER_PATH,
  CATEGORIES_PATH,
  REWARDS_CODE_BY_UID_AND_BY_USER,
  CLOSET_CASH_DISCOUNT_CODES_PATH,
  SHOPIFY_STORE_PARAM,
  SHOPIFY_STORES,
  DISCOUNT_CODE_ID_PARAM,
  CLOSET_CASH_DISCOUNT_CODE_CLAIM_PATH,
  REDEMPTION_CODE_PARAM,
  REGENERATION_SHIPPING_LABEL_PATH,
  TOTAL_IMPACT_PATH,
  EARNINGS_CAROUSEL_PATH,
  SEND_SIGN_IN_EMAIL_LINK_PATH,
  SHIPPING_PROVIDERS_PATH,
  REDEMPTIONS_PATH,
  GIFT_CARDS_BRANDS_PATH,
  BRAND_ID_PARAM,
  GIFT_CARDS_BRAND_PATH,
  GIFT_CARDS_CATEGORIES_PATH,
  GIFT_CARDS_BRAND_SEARCH_PATH,
  GIFT_CARD_ID_PARAM,
  GIFT_CARD_BY_UID_PATH,
  GIFT_CARDS_ISSUE_PATH,
  GIFT_CARDS_USER_PATH,
  GIFT_CARDS_USED_PATH,
  AUTH_USERS_PATH,
  SIMILAR_AND_TRENDING_REWARDS_PATH,
  FEATURED_REWARDS_PATH,
};
