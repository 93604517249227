import classNames from 'classnames';
import './TitleSectionAccount.scss';

const TitleSectionAccount = ({
  title,
  subTitle,
  url,
  actionTitle,
  className,
}) => (
  <div className={classNames('TitleSectionAccount', className)}>
    <div className="TitleSectionAccount__titleStyle">{title}</div>
    {(subTitle || actionTitle) && (
      <div className="TitleSectionAccount__subTitleSection">
        <div className="TitleSectionAccount__subTitleStyle">
          {subTitle}
          {' '}
          <a href={url} className="TitleSectionAccount__urlTitleStyle">{actionTitle}</a>
        </div>
      </div>
    )}
  </div>
);

export default TitleSectionAccount;
