import { formatCurrencyNumber } from '../utils/numbers';

const REWARD_TYPE = {
  VANITY: 'VANITY',
  ONE_TIME: 'ONE_TIME',
  CODELESS: 'CODELESS',
  SHOPIFY_DISCOUNT_CODE: 'SHOPIFY_DISCOUNT_CODE',
  DONATION: 'DONATION',
  SEGMENTED_BY_PURCHASED_SKU: 'SEGMENTED_BY_PURCHASED_SKU',
};

const REWARD_AMOUNT_TYPES_ID = {
  FREE_PRODUCT: 'FREE_PRODUCT',
  BUNDLE_AND_SAVE: 'BUNDLE_AND_SAVE',
  FREE_CLASS: 'FREE_CLASS',
  FREE_TRIAL_WEEK: 'FREE_TRIAL_WEEK',
  FREE_TRIAL_MONTH: 'FREE_TRIAL_MONTH',
  FREE_DELIVERY: 'FREE_DELIVERY',
  BONUS: 'BONUS',
  VALUE: 'VALUE',
  PERCENTAGE: 'PERCENTAGE',
};

const REWARD_AMOUNT_TYPES = {
  [REWARD_AMOUNT_TYPES_ID.FREE_PRODUCT]: {
    hasAmount: false,
    sortRanking: 0,
    getText: () => 'A FREE PRODUCT',
  },
  [REWARD_AMOUNT_TYPES_ID.BUNDLE_AND_SAVE]: {
    hasAmount: false,
    sortRanking: 1,
    getText: () => 'A BUNDLE AND SAVE REWARD',
  },
  [REWARD_AMOUNT_TYPES_ID.FREE_CLASS]: {
    hasAmount: true,
    sortRanking: 2,
    getText: ({ rewardAmount }) => `${rewardAmount} FREE ${rewardAmount > 1 ? 'CLASSES' : 'CLASS'}`,
  },
  [REWARD_AMOUNT_TYPES_ID.FREE_TRIAL_WEEK]: {
    hasAmount: true,
    sortRanking: 3,
    getText: ({ rewardAmount }) => `${rewardAmount} ${rewardAmount > 1 ? 'WEEKS' : 'WEEK'} FREE`,
  },
  [REWARD_AMOUNT_TYPES_ID.FREE_TRIAL_MONTH]: {
    hasAmount: true,
    sortRanking: 4,
    getText: ({ rewardAmount }) => `${rewardAmount} ${rewardAmount > 1 ? 'MONTHS' : 'MONTH'} FREE`,
  },
  [REWARD_AMOUNT_TYPES_ID.BONUS]: {
    hasAmount: true,
    sortRanking: 5,
    getText: ({ rewardAmount, sup = false, format = false }) => (
      <span>
        {`${(format && formatCurrencyNumber(rewardAmount)) || `$${rewardAmount}`}`}
        {sup ? <sup>BONUS</sup> : ' BONUS'}
      </span>
    ),
  },
  [REWARD_AMOUNT_TYPES_ID.VALUE]: {
    hasAmount: true,
    sortRanking: 6,
    getText: ({ rewardAmount, type, sup = false, format = false }) => {
      let suffix;
      switch (type) {
        case REWARD_TYPE.DONATION:
          suffix = 'DONATION';
          break;
        case REWARD_TYPE.SEGMENTED_BY_PURCHASED_SKU:
          suffix = 'CREDIT';
          break;
        default:
          suffix = 'OFF';
          break;
      }

      return (
        <span>
          {`${(format && formatCurrencyNumber(rewardAmount)) || `$${rewardAmount}`}`}
          {sup ? <sup>{suffix}</sup> : ` ${suffix}`}
        </span>
      );
    },
  },
  [REWARD_AMOUNT_TYPES_ID.PERCENTAGE]: {
    hasAmount: true,
    sortRanking: 7,
    getText: ({ rewardAmount, sup = false }) => (
      <span>
        {`${rewardAmount}%`}
        {sup ? <sup>OFF</sup> : ' OFF'}
      </span>
    ),
  },
  [REWARD_AMOUNT_TYPES_ID.FREE_DELIVERY]: {
    hasAmount: false,
    sortRanking: 8,
    getText: () => 'A FREE DELIVERY',
  },
};

const REWARDS_VALIDATED_STATE = {
  NONE: 'none',
  NOT_ENOUGH_CASH: 'notEnoughCash',
  SOLD_OUT: 'soldOut',
  USER_LIMIT: 'userLimit',
  ALL_DISABLED: 'allDisabled',
};

const REWARD_EXPIRE_AT_TAG = '%expireAt%';
const REWARD_TERMS_PREFIX = 'Once you’ve redeemed your TrashieCash™ for a reward, the withdrawal from your TrashieCash™ balance is permanent.';
const REWARD_TEMS_PREFIX_2ND = 'Please view the terms and conditions below for more details on merchant restrictions and exclusions.';
const REWARD_TEMS_PREFIX_3RD = 'TrashieCash™ Terms and Conditions apply and are subject to change.';
const REWARD_TERMS_SUFFIX_INFORMATION = ' You can access this reward in your email confirmation or';
const REWARD_TERMS_SUFFIX = ' Thanks for being a part of the Trashie community!';

const REWARDS_ORDER_OPTIONS = {
  UPDATED_AT: 'updatedAt',
  EXPIRE_AT: 'expireAt',
  REDEEMED_AT: 'redeemedAt',
};

const REWARD_PREVIEW_TYPES = Object.freeze({
  DEFAULT: 'default',
  STARTER: 'starter',
  FEATURED: 'featured',
  HOT: 'hot',
  CATEGORY: 'category',
  UNLOCK: 'unlock',
  SIMILAR: 'similar',
  TRENDING: 'trending',
});

export {
  REWARD_TYPE,
  REWARD_AMOUNT_TYPES_ID,
  REWARD_AMOUNT_TYPES,
  REWARDS_VALIDATED_STATE,
  REWARD_EXPIRE_AT_TAG,
  REWARD_TERMS_PREFIX,
  REWARD_TERMS_SUFFIX,
  REWARD_TERMS_SUFFIX_INFORMATION,
  REWARDS_ORDER_OPTIONS,
  REWARD_TEMS_PREFIX_2ND,
  REWARD_TEMS_PREFIX_3RD,
  REWARD_PREVIEW_TYPES,
};
